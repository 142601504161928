'use client'

import { clsx } from 'clsx'
import {
  type Dispatch,
  type FC,
  type RefObject,
  type SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { IconClose } from '@redteclab/icons'

import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { urlResolverGetSearchPage } from '../../url-handling/urlResolver'
import { useExperimentsContext } from '../components/ExperimentsContext'
import {
  EXPERIMENTS_TRACK_EVENT_ADVERTISING_FLYOUT_BANNER,
  EXPERIMENTS_TRACK_EVENT_ADVERTISING_FLYOUT_BANNER_CLOSED,
  experimentsTrackEvent,
} from '../eventTracking'
import { EXPERIMENT_NAME_ADVERTISING_FLYOUT_BANNER } from '../model/EXPERIMENT_NAME'
import { EXPERIMENT_VARIATION } from '../model/EXPERIMENT_VARIATION'
import { experimentAccessorGetExperimentData } from '../model/experimentAccessor'

import { AdvertisingFlyoutBannerButton } from './AdvertisingFlyoutBannerButton'
import { type AdvertisingFlyoutBannerConfiguration } from './config/getAdvertisingFlyoutBannerConfigurationByTenant'
import { DiscountBubble } from './DiscountBubble'
import { ImageWithLink } from './ImageWithLink'

const EXECUTION_TIMEOUT = 15_000

const isBannerLogicExecuted = (): boolean => {
  try {
    return Boolean(
      sessionStorage.getItem(EXPERIMENT_NAME_ADVERTISING_FLYOUT_BANNER),
    )
  } catch {
    return true
  }
}

const setBannerLogicExecuted = (): void => {
  try {
    sessionStorage.setItem(EXPERIMENT_NAME_ADVERTISING_FLYOUT_BANNER, 'true')
  } catch {
    // noop
  }
}

const useHandleClickOutsideBanner = (
  bannerRef: RefObject<HTMLElement | null>,
  setShouldDisplayBanner: Dispatch<SetStateAction<boolean>>,
  setShouldDisplayButton: Dispatch<SetStateAction<boolean>>,
): void => {
  useEffect(() => {
    if (isBannerLogicExecuted()) {
      return undefined
    }

    const handleClickOutside = (event: MouseEvent): void => {
      if (
        bannerRef.current &&
        !bannerRef.current.contains(event.target as Node)
      ) {
        setShouldDisplayBanner(false)
        setShouldDisplayButton(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [bannerRef, setShouldDisplayBanner, setShouldDisplayButton])
}

const useHandleDisplayBannerButtonAfterTimeout = (
  setShouldDisplayButton: Dispatch<SetStateAction<boolean>>,
): void => {
  const { addExperimentKeysForActivation } = useExperimentsContext()

  useEffect(() => {
    if (isBannerLogicExecuted()) {
      return undefined
    }

    const buttonTimeout = window.setTimeout(() => {
      setShouldDisplayButton(true)
      addExperimentKeysForActivation(EXPERIMENT_NAME_ADVERTISING_FLYOUT_BANNER)
    }, EXECUTION_TIMEOUT)

    return (): void => {
      clearTimeout(buttonTimeout)
    }
  }, [addExperimentKeysForActivation, setShouldDisplayButton])
}

const useHandleDisplayBannerAfterTimeout = (
  setShouldDisplayBanner: Dispatch<SetStateAction<boolean>>,
  setShouldDisplayButton: Dispatch<SetStateAction<boolean>>,
  shouldDisplayButton: boolean,
): void => {
  useEffect(() => {
    if (isBannerLogicExecuted() || !shouldDisplayButton) {
      return undefined
    }

    const bannerShowTimeout = window.setTimeout(() => {
      setShouldDisplayButton(false)
      setShouldDisplayBanner(true)
    }, EXECUTION_TIMEOUT)

    return (): void => {
      clearTimeout(bannerShowTimeout)
    }
  }, [setShouldDisplayBanner, setShouldDisplayButton, shouldDisplayButton])
}

const useHandleHideBannerAfterTimeout = (
  setShouldDisplayBanner: Dispatch<SetStateAction<boolean>>,
  setShouldDisplayButton: Dispatch<SetStateAction<boolean>>,
  shouldDisplayBanner: boolean,
): void => {
  useEffect(() => {
    if (isBannerLogicExecuted() || !shouldDisplayBanner) {
      return undefined
    }

    const bannerHideTimeout = window.setTimeout(() => {
      setBannerLogicExecuted()

      setShouldDisplayButton(true)
      setShouldDisplayBanner(false)
    }, EXECUTION_TIMEOUT)

    return (): void => {
      clearTimeout(bannerHideTimeout)
    }
  }, [setShouldDisplayBanner, setShouldDisplayButton, shouldDisplayBanner])
}

type AdvertisingFlyoutBannerProps = {
  advertisingFlyoutBannerConfig: AdvertisingFlyoutBannerConfiguration | null
}

// eslint-disable-next-line max-lines-per-function -- the rule has been disabled because this is an experiment and this wouldn't be the case once we implement it
export const AdvertisingFlyoutBanner: FC<AdvertisingFlyoutBannerProps> = ({
  advertisingFlyoutBannerConfig,
}) => {
  const { experiments } = useExperimentsContext()
  const config = useGlobalConfigContext()

  const experimentData = useMemo(() => {
    const advertisingFlyoutBannerExperimentData =
      experimentAccessorGetExperimentData(
        experiments,
        EXPERIMENT_NAME_ADVERTISING_FLYOUT_BANNER,
      )
    const isExperimentEnabled = advertisingFlyoutBannerExperimentData?.isEnabled
    const variant = advertisingFlyoutBannerExperimentData?.variant

    return {
      isExperimentEnabled,
      variant,
    }
  }, [experiments])

  const { isExperimentEnabled, variant } = experimentData
  const bannerLink = urlResolverGetSearchPage(config, {
    queryParams: {
      // eslint-disable-next-line id-length -- search parameter
      b: '1',
      query: 'redcare',
    },
  })
  const bannerRef = useRef<HTMLElement | null>(null)
  const [shouldDisplayButton, setShouldDisplayButton] = useState(false)
  const [shouldDisplayBanner, setShouldDisplayBanner] = useState(false)

  // Checks if user has clicked outside the banner and then closes it
  useHandleClickOutsideBanner(
    bannerRef,
    setShouldDisplayBanner,
    setShouldDisplayButton,
  )
  // Displays the button after a timeout if the banner hasn't already been displayed before, on first load
  useHandleDisplayBannerButtonAfterTimeout(setShouldDisplayButton)
  // Displays the banner after a timeout after the button has been displayed, on first load
  useHandleDisplayBannerAfterTimeout(
    setShouldDisplayBanner,
    setShouldDisplayButton,
    shouldDisplayButton,
  )
  // Hides the banner after a timeout after the banner has been displayed, on first load
  useHandleHideBannerAfterTimeout(
    setShouldDisplayBanner,
    setShouldDisplayButton,
    shouldDisplayBanner,
  )

  if (
    !advertisingFlyoutBannerConfig ||
    !isExperimentEnabled ||
    variant === EXPERIMENT_VARIATION.DEFAULT
  ) {
    return undefined
  }

  return (
    <aside
      className={clsx(
        'fixed top-1/2 z-30 h-0',
        (variant === EXPERIMENT_VARIATION.V4 ||
          variant === EXPERIMENT_VARIATION.V2) &&
          'left-0',
      )}
      ref={bannerRef}
    >
      {shouldDisplayButton ? (
        <AdvertisingFlyoutBannerButton
          className={clsx(
            (variant === EXPERIMENT_VARIATION.V4 ||
              variant === EXPERIMENT_VARIATION.V2) &&
              'rotate-90 mobile-sm:translate-x-[-44%] desktop:translate-x-[-40%]',
          )}
          onClick={(): void => {
            setBannerLogicExecuted()
            setShouldDisplayBanner(true)
            setShouldDisplayButton(false)
          }}
        />
      ) : null}
      {shouldDisplayBanner ? (
        <div className="flex -translate-y-1/2 cursor-pointer flex-row items-center justify-end">
          <ImageWithLink
            link={bannerLink}
            onClick={(): void => {
              experimentsTrackEvent(
                config,
                EXPERIMENTS_TRACK_EVENT_ADVERTISING_FLYOUT_BANNER,
              )
              setBannerLogicExecuted()
            }}
            src={
              variant === EXPERIMENT_VARIATION.V4
                ? advertisingFlyoutBannerConfig.v4
                : advertisingFlyoutBannerConfig.v2
            }
          />
          {variant === EXPERIMENT_VARIATION.V4 && (
            <DiscountBubble tenant={config.tenant} />
          )}
          <IconClose
            className="absolute right-0 top-0 size-6 fill-dark-info-strong"
            onClick={(): void => {
              experimentsTrackEvent(
                config,
                EXPERIMENTS_TRACK_EVENT_ADVERTISING_FLYOUT_BANNER_CLOSED,
              )
              setBannerLogicExecuted()
              setShouldDisplayBanner(false)
              setShouldDisplayButton(false)
            }}
          />
        </div>
      ) : null}
    </aside>
  )
}
