import { clsx } from 'clsx'
import { type FC } from 'react'

import { BrandIconApp } from '@redteclab/brand-icons'

import { BrandIcon, Button, DialogContent, Link } from 'base-ui'

import { useDeviceDetectorContext } from '../../device-detector/context/deviceDetectorContext'
import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { StaticImage } from '../../image/StaticImage'
import { urlResolverNfcLandingPage } from '../../url-handling/urlResolver'
import {
  EXPERIMENTS_TRACK_EVENT_ERX_MODAL_CTA_CLICK,
  experimentsTrackEvent,
} from '../eventTracking'
import { type ExperimentType } from '../model/Experiment.types'

import appQrCode from './images/sae-app-qr-code.png'
import saeAppStoresImg from './images/sae-app-stores.png'

type ErxModalContentProps = {
  experimentErxModal: ExperimentType
  onModalClose: () => void
}
const ErxModalContent: FC<ErxModalContentProps> = ({
  experimentErxModal,
  onModalClose,
}) => {
  const globalConfig = useGlobalConfigContext()
  const { isMobile } = useDeviceDetectorContext()

  const redirectLink = isMobile
    ? 'https://shop-apotheke-com.onelink.me/d95Q?pid=Onsite&af_channel=DE&c=DE_Erx_Popup0424_mobile_banner&af_dp=sae://genVoucherCampaign?id=1001&deep_link_value=sae://genVoucherCampaign?id=1001&af_web_dp=https://www.shop-apotheke.com/lp/shop-apotheke-app/'
    : 'https://shop-apotheke-com.onelink.me/d95Q?pid=Onsite&af_channel=DE&c=DE_Erx_Popup0424_qr&af_dp=sae://genVoucherCampaign?id=1001&deep_link_value=sae://genVoucherCampaign?id=1001&af_web_dp=https://www.shop-apotheke.com/lp/shop-apotheke-app/'

  /* eslint-disable react/jsx-no-literals -- Since this is only for COM and will be removed soon, we don't need to add translations. */
  return (
    <DialogContent className="relative flex justify-center bg-light-primary-low mobile-sm:rounded-xl mobile-sm:rounded-t-none">
      <div className="flex max-w-[520px] flex-col items-center justify-center text-center mobile-sm:rounded-xl mobile-sm:rounded-t-none">
        <h2 className="text-xl font-medium">
          Endlich digital: E-Rezept <br className="tablet:hidden" /> in
          <i className="text-dark-brand"> unserer App</i> einlösen.
        </h2>
        <div className="mx-12">
          <p className="my-3">
            Jetzt ausprobieren: <br />
            Erstes E-Rezept mit unserer App und Krankenkassenkarte einlösen und
            bis zu 10€ sparen.<sup>28</sup>
          </p>
          <p className="my-3">
            Ihr Gutscheincode wird direkt in der App angezeigt.
          </p>
          <div className="tablet:hidden">
            <Link
              href={redirectLink}
              onClick={onModalClose}
              {...(isMobile
                ? {
                    rel: 'noreferrer',
                    target: '_blank',
                  }
                : undefined)}
            >
              <StaticImage
                alt="sae-app-icon"
                className="m-auto w-full max-w-xs"
                src={saeAppStoresImg}
              />
            </Link>
          </div>
          <div className="my-3 hidden items-center rounded-2xl bg-light-brand-high p-3 tablet:flex">
            <BrandIcon icon={BrandIconApp} size="l" />
            <p className="ml-4 text-left text-m font-medium">
              Jetzt scannen und App runterladen.
            </p>

            <StaticImage
              alt="app-qr-code"
              className="m-auto rounded-md"
              src={appQrCode}
              width={110}
            />
          </div>

          <Button<'a'>
            className={clsx(
              'w-48 text-s after:absolute',
              isMobile ? 'mb-1 mt-4 min-h-0 text-dark-brand underline' : 'mt-3',
            )}
            href={urlResolverNfcLandingPage(globalConfig)}
            onClick={() => {
              if (experimentErxModal.isEnabled) {
                experimentsTrackEvent(
                  globalConfig,
                  EXPERIMENTS_TRACK_EVENT_ERX_MODAL_CTA_CLICK,
                )
              }
              onModalClose()
            }}
            rel="noreferrer"
            slots={{ root: 'a' }}
            target="_blank"
            variant={isMobile ? 'text' : 'outlined'}
          >
            Mehr Informationen
          </Button>
        </div>
      </div>
    </DialogContent>
  )
  /* eslint-enable react/jsx-no-literals */
}

export { ErxModalContent }
