'use client'

import {
  type ChangeEvent,
  createContext,
  type FC,
  type ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useDebounceCallback } from 'usehooks-ts'

import {
  ALGOLIA_SEARCH_CHANNEL,
  algoliaGetTokenFromLocalStorage,
  type AlgoliaQueryRecord,
  type AlgoliaRecord,
  useAlgoliaConfigContext,
} from '../../../algolia'
import {
  type PublicGlobalConfig,
  useGlobalConfigContext,
} from '../../../global-config'
import { urlResolverGetSearchPage } from '../../../url-handling'

import { type HeaderSearchContextValue } from './components/types'
import { headerSearchSessionStorageTrackingTrackSuggestItemClick } from './headerSearchSessionStorageTracking'
import {
  previousSearchesStorageAddPreviousSearches,
  usePreviousSearchControls,
} from './previousSearchesStorage'
import { useSearchClientControls } from './useSearchClientControls'

const HeaderSearchContext = createContext<HeaderSearchContextValue | null>(null)
const HEADER_SEARCH_TYPEAHEAD_MIN_QUERY_LEN = 3
const ALGOLIA_SUGGEST_OPTIMISATION_DEBOUNCE_REQUEST_TIME = 500

const navigateToSearchResults = ({
  config,
  query,
}: {
  config: PublicGlobalConfig
  query: string
}): void => {
  window.location.href = urlResolverGetSearchPage(config, {
    queryParams: {
      // eslint-disable-next-line id-length -- "i" represents that the search is internal, e.g. originates from site itself
      i: '1',
      query,
      searchChannel: ALGOLIA_SEARCH_CHANNEL,
      userToken: algoliaGetTokenFromLocalStorage(),
    },
  })
}
/**
 * constructs the url for the search page and navigates to it.
 */
const handleSubmitSearch = (
  config: PublicGlobalConfig,
  inputControls: HeaderSearchContextValue['inputControls'],
): void => {
  const { searchInputValue } = inputControls
  if (!searchInputValue) {
    return
  }
  /** sometimes searchInputValue is different from searchQuery,
   * e.g. when user types in search input, navigates using keyboard
   * in this case searchInputValue will be different from searchQuery
   * therefore it is better to use searchInputValue instead of searchQuery
   */
  previousSearchesStorageAddPreviousSearches(searchInputValue)
  navigateToSearchResults({ config, query: searchInputValue })
}

/**
 * State management for search input.
 * Important to note that searchQuery and actual value on input are not same state.
 * searchQuery will actually be used to query algolia, while actual value on input is used to display the value on input.
 * so at given time they may contain different values.
 * That was needed for autocompletion on arrow keys, due to input may contain some value that should not trigger actual search
 */
const useInputControls = (): HeaderSearchContextValue['inputControls'] => {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchInputValueOnState, setSearchInputValueOnState] = useState('')
  const [renderedSearchRecords, setRenderedSearchRecords] = useState<
    AlgoliaRecord[]
  >([])
  const [renderedSuggestionRecords, setRenderedSuggestionRecords] = useState<
    AlgoliaQueryRecord[]
  >([])
  const debouncedSearchQueryUpdate = useDebounceCallback(
    setSearchQuery,
    ALGOLIA_SUGGEST_OPTIMISATION_DEBOUNCE_REQUEST_TIME,
  )

  /**
   * sets value on input and updates search query if needed
   * @param value value to be set on input
   * @param updateQuery if true search query will be updated to match value, effectively triggering search
   */
  const setSearchInputValue: HeaderSearchContextValue['inputControls']['setSearchInputValue'] =
    useCallback(
      (value, { updateSearchQuery }) => {
        if (
          updateSearchQuery &&
          value.length >= HEADER_SEARCH_TYPEAHEAD_MIN_QUERY_LEN
        ) {
          debouncedSearchQueryUpdate(value)
          if (value.length === HEADER_SEARCH_TYPEAHEAD_MIN_QUERY_LEN) {
            debouncedSearchQueryUpdate.flush()
          }
        }
        setSearchInputValueOnState(value)
      },
      [debouncedSearchQueryUpdate],
    )

  return useMemo((): HeaderSearchContextValue['inputControls'] => {
    return {
      clearSearch: (): void => {
        setSearchInputValue('', { updateSearchQuery: true })
      },
      handleInputChange: (event: ChangeEvent<HTMLInputElement>): void => {
        setSearchInputValue(event.target.value, {
          updateSearchQuery: true,
        })
      },
      renderedSearchRecords,
      renderedSuggestionRecords,

      /**
       * value on input itself, when needed it can be used to update search query
       */
      searchInputValue: searchInputValueOnState,
      searchQuery,
      setRenderedSearchRecords,
      setRenderedSuggestionRecords,
      setSearchInputValue,
    }
  }, [
    renderedSearchRecords,
    renderedSuggestionRecords,
    searchInputValueOnState,
    searchQuery,
    setSearchInputValue,
  ])
}
/**
 * State management for header search.
 * @remark:
 * part of header search/results is loaded dynamically. Dynamically loaded component will set e.g. client search to context,
 * so it's as well available higher than dyn. loaded components.
 */
export const HeaderSearchContextProvider: FC<{
  children: ReactNode
}> = ({ children }) => {
  const [searchIsActive, setSearchIsActive] = useState(false)
  const searchClientControls = useSearchClientControls()
  const previousSearchControls = usePreviousSearchControls()
  const inputControls = useInputControls()
  const configuration = useAlgoliaConfigContext()
  const globalConfig = useGlobalConfigContext()
  const [portalResultsTo, setPortalResultsTo] = useState<
    HTMLDivElement | null | undefined
  >(undefined)

  const contextValue: HeaderSearchContextValue = useMemo(
    (): HeaderSearchContextValue => ({
      configuration,
      handleSubmitSearch: (): void => {
        handleSubmitSearch(globalConfig, inputControls)
      },
      inputControls,
      portalResultsTo,
      previousSearchControls,
      searchClientControls,
      searchIsActive,
      setSearchIsActive: ({
        portalResultsToElement,
        value,
      }: {
        portalResultsToElement?: HTMLDivElement | null
        value: boolean
      }): void => {
        setSearchIsActive(value)
        setPortalResultsTo(portalResultsToElement)
      },

      /**
       * if search is active but user did not yet type anything, some default recommendations: top queries and top seller products will be shown
       */
      shouldShowDefaultRecommendations: (): boolean => {
        return searchIsActive && inputControls.searchQuery.length === 0
      },

      /**
       * https://jira.shop-apotheke.com/browse/WSAWA-2281
       */
      trackSuggestItemClick: (): void => {
        headerSearchSessionStorageTrackingTrackSuggestItemClick({
          qSuggest: inputControls.renderedSearchRecords?.length ?? 0,
          searchPhrase: inputControls.searchQuery,
        })
      },
    }),
    [
      configuration,
      globalConfig,
      inputControls,
      portalResultsTo,
      searchClientControls,
      searchIsActive,
      previousSearchControls,
    ],
  )

  return (
    <HeaderSearchContext.Provider value={contextValue}>
      {children}
    </HeaderSearchContext.Provider>
  )
}

export const useHeaderSearchContext = (): HeaderSearchContextValue => {
  const contextValue = useContext(HeaderSearchContext)
  if (!contextValue) {
    throw new Error(
      'useHeaderSearchStore must be used within a HeaderSearchStoreProvider',
    )
  }

  return contextValue
}
