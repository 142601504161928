import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  type CmsBlockMenuItemsInner,
  CmsComponentMenuSpacerSchemaEnum,
} from '@redteclab/api/clients/content-management-system'

import { useExpaContext } from '../../../../expa'
import { useGlobalConfigContext } from '../../../../global-config'
import { TENANT } from '../../../../tenant'
import { urlResolverNfcLandingPage } from '../../../../url-handling'

import { HeaderNavigationItemRenderer } from './HeaderNavigationItemRenderer'

interface HeaderNavigationProps {
  menuItems: CmsBlockMenuItemsInner[]
}

/**
 * @example
 * ['menuCategories', 'menuCategories', 'spacer', 'menuCategories']
 * returns
 * [['menuCategories', 'menuCategories'], ['menuCategories']]
 */
const groupSplitBySpacer = (
  menuItems: CmsBlockMenuItemsInner[],
): CmsBlockMenuItemsInner[][] => {
  const spacerIndex = menuItems.findIndex(
    (it) =>
      it.schema === CmsComponentMenuSpacerSchemaEnum.CmsComponentMenuSpacer,
  )

  if (spacerIndex === -1) {
    return [menuItems]
  }

  const beforeSpacer = menuItems.slice(0, spacerIndex)
  const afterSpacer = menuItems.slice(spacerIndex + 1)

  return [beforeSpacer, afterSpacer]
}

export const HeaderNavigation: FC<HeaderNavigationProps> = ({ menuItems }) => {
  const expa = useExpaContext()
  const globalConfig = useGlobalConfigContext()
  const { superScripts, tenant } = globalConfig

  const listGroups = groupSplitBySpacer(
    menuItems.filter((item) => {
      return 'cleansite' in item
        ? !item.cleansite?.some((cleansite) => cleansite === expa)
        : true
    }),
  )

  return (
    <nav className="mx-auto max-w-5xl">
      {/* eslint-disable-next-line react/jsx-no-literals -- the rule was disabled before a comment became required */}
      <h3 className="sr-only">Menubar</h3>
      <div className="flex justify-between">
        {listGroups.map((listGroup, listGroupIndex) => (
          <ul
            className="flex max-h-14 flex-wrap overflow-hidden last-of-type:shrink-0"
            key={listGroupIndex} // eslint-disable-line react/no-array-index-key -- the rule was disabled before a comment became required
          >
            {listGroup.map((item, itemIndex) => (
              <HeaderNavigationItemRenderer
                item={item}
                key={itemIndex} // eslint-disable-line react/no-array-index-key -- the rule was disabled before a comment became required
              />
            ))}
            {tenant === TENANT.COM ? (
              <li className="flex items-center pr-3.5">
                <a
                  className="whitespace-nowrap rounded-2xl bg-light-tertiary px-2 py-0.5 text-[10px] font-medium leading-[1.5]"
                  data-qa-id="menubar-bonus-link"
                  href={urlResolverNfcLandingPage(globalConfig)}
                >
                  <FormattedMessage id="header.link.voucher" />
                  {superScripts.voucher ? (
                    <sup>{superScripts.voucher}</sup>
                  ) : null}
                </a>
              </li>
            ) : null}
          </ul>
        ))}
      </div>
    </nav>
  )
}
