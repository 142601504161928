import { type FC } from 'react'

import {
  type FooterMenuCategory,
  type FooterMenuLink,
} from '../../../config/PerTenantFooterConfiguration.type'

const getLinkAsString = (className: string, item: FooterMenuLink): string =>
  `<a class="${className}" href="${item.href}">${item.title}</a>`

// eslint-disable-next-line no-script-url -- comes from CMS
const JAVASCRIPT_URL = 'javascript:'

type FooterLinkCategoryProps = {
  category: FooterMenuCategory
  noTitle?: boolean
}

export const FooterLinkCategory: FC<FooterLinkCategoryProps> = ({
  category,
  noTitle,
}) => (
  <div>
    {!noTitle && (
      <div className="mb-4 text-s font-medium text-dark-primary-max">
        {category.title}
      </div>
    )}
    <ul className="hyphens-auto break-words">
      {category.items.map((item) => {
        const hasJavaScriptUrl = item.href.includes(JAVASCRIPT_URL)
        const linkClasses =
          'text-s text-dark-primary-strong hover:text-dark-pressed-brand'

        return (
          <li
            className="mb-2"
            /*
             * Data coming from the CMS can have "javascript:" URLs, which trigger a warning in React.
             * We need to use "dangerouslySetInnerHTML" to bypass that warning.
             */
            // eslint-disable-next-line react/no-danger -- bypass warning
            dangerouslySetInnerHTML={
              hasJavaScriptUrl
                ? { __html: getLinkAsString(linkClasses, item) }
                : undefined
            }
            key={item.title}
          >
            {/* When using "dangerouslySetInnerHTML" we need to explicitly set "children" to "undefined"
              because they are not allowed at the same time */}
            {hasJavaScriptUrl ? undefined : (
              <a
                className={linkClasses}
                data-qa-id="footer-links"
                href={item.href}
              >
                {item.title}
              </a>
            )}
          </li>
        )
      })}
    </ul>
  </div>
)
