import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button } from 'base-ui'

import { useCopyText } from '../../generic-hooks/useCopyText/useCopyText'

type CopyVoucherButtonProps = {
  textToCopy: string
}

export const CopyVoucherButton: FC<CopyVoucherButtonProps> = ({
  textToCopy,
}) => {
  const { copied, copyText } = useCopyText(textToCopy)

  return copied ? (
    <span className="text-center text-xs">
      <FormattedMessage id="voucherBox.input.copiedLabel.button" />
    </span>
  ) : (
    <Button
      className="m-0 bg-transparent p-0 text-xs font-normal text-dark-primary-max"
      onClick={(): void => {
        void copyText()
      }}
      variant="text"
    >
      <FormattedMessage id="voucherBox.input.shortLabel.button" />
    </Button>
  )
}
