import useSWR, { type SWRResponse } from 'swr'

import { ContentControllerV3ApiFactory } from '@redteclab/api/clients/bully'
import { type FockNode } from '@redteclab/api/clients/content-management-system'

import { useBullyApi } from '../../../../base-api-communication'
import {
  type PublicGlobalConfig,
  useGlobalConfigContext,
} from '../../../../global-config'

/**
 * fetch cms content node by category path (needed for fetching nodes of the root header menu items)
 */
const fetchByCode = async (
  code: string,
  client: ReturnType<typeof ContentControllerV3ApiFactory>,
  globalConfig: PublicGlobalConfig,
): Promise<FockNode> => {
  const response =
    await client.languageContentV3TenantNodesByCodeCategoryCodeGet({
      categoryCode: code,
      language: globalConfig.language,
      tenant: globalConfig.tenantAndEnv,
    })

  return response.data as FockNode
}

/**
 * fetch cms content node by code path (needed for fetching nodes of the cms nodes)
 */
const fetchByCategory = async (
  categoryPath: string,
  client: ReturnType<typeof ContentControllerV3ApiFactory>,
  globalConfig: PublicGlobalConfig,
): Promise<FockNode> => {
  const response = await client.languageContentV3TenantNodesPathGet({
    language: globalConfig.language,
    path: categoryPath,
    tenant: globalConfig.tenantAndEnv,
  })

  return response.data as FockNode
}

export type CategoryOrCodeIdentifier = {
  categoryPath?: string
  code?: string
}

const useHeaderApiFetchHeaderMenuChildNodesGetKey = (
  identifierTuple: CategoryOrCodeIdentifier,
): string => {
  const { categoryPath, code } = identifierTuple

  const categoryOrCode = code ?? categoryPath ?? ''

  return `useHeaderApiFetchHeaderMenuChildNodes-${categoryOrCode}`
}

/**
 * swr key constructed with category or code,
 * this returns the category or node identifier
 * @param key
 */
const getIdentifierFromSwrKey = (key: string): string => {
  const firstDash = key.indexOf('-')

  return key.slice(firstDash + 1)
}

/**
 * This is controlled query to fetch a header menu node.
 * Controlled means that this query shall be explicitly called via the returned 'load' function.
 *
 * menu node on cms comes from two different endpoints, one that is queried by categoryPath and one that is queried by code.
 * this swr, calls corresponding endpoint depending on if categoryPath or code is provided.
 */
export const useHeaderApiSwrFetchHeaderMenuChildNodes = ({
  identifierTuple = {},
  onDataLoaded,
  onError,
}: {
  identifierTuple?: CategoryOrCodeIdentifier
  onDataLoaded: (key: string, data: FockNode) => void
  onError: (error: unknown) => void
}): SWRResponse<FockNode> => {
  const globalConfig = useGlobalConfigContext()
  const client = useBullyApi(ContentControllerV3ApiFactory)

  const fetcher = async (): Promise<FockNode> => {
    const { categoryPath, code } = identifierTuple
    if (code) {
      return fetchByCode(code, client, globalConfig)
    }

    if (categoryPath) {
      return fetchByCategory(categoryPath, client, globalConfig)
    }

    throw new Error('code or categoryPath is required')
  }

  return useSWR<FockNode>(
    useHeaderApiFetchHeaderMenuChildNodesGetKey(identifierTuple),
    fetcher,
    {
      onError,
      onSuccess: (data, key) => {
        onDataLoaded(getIdentifierFromSwrKey(key), data)
      },
      revalidateOnMount: false,
    },
  )
}
