import { type ComponentProps, type ComponentType, type FC } from 'react'

import { CmsComponentStickyBannerTopHeaderSchemaEnum } from '@redteclab/api/clients/content-management-system'
import { IconPrescription } from '@redteclab/icons'

import { useExpaContext } from '../../../../expa'
import { IconPrimaryArrow } from '../../../../icons'
import { useHeaderDataContext } from '../../headerConfigContext'

const icons: Record<string, ComponentType<ComponentProps<'svg'>>> = {
  prescription: IconPrescription,
  primaryArrow: IconPrimaryArrow,
}

export const TopHeaderStickyBanner: FC = () => {
  const headerData = useHeaderDataContext()
  const expa = useExpaContext()

  if (!headerData.cmsMenu.items) {
    return null
  }

  const stickyBannerTopHeader = headerData.cmsMenu.items.find(
    (item) =>
      item.schema ===
      CmsComponentStickyBannerTopHeaderSchemaEnum.CmsComponentStickyBannerTopHeader,
  )

  if (!stickyBannerTopHeader || stickyBannerTopHeader.hidden === true) {
    return null
  }

  const {
    cleansite,
    icon,
    link,
    text: mainText,
    text_mobile,
  } = stickyBannerTopHeader

  /**
   * usecase e.g. for CH - when gglp hide prescription ribbon
   * https://jira.shop-apotheke.com/browse/WSAWA-5470
   */
  if (expa && cleansite?.includes(expa)) {
    return null
  }

  const Icon = icon && icons[icon]

  let text = (
    <>
      <span
        className="hidden tablet:inline"
        // eslint-disable-next-line react/no-danger -- the rule was disabled before a comment became required
        dangerouslySetInnerHTML={{
          __html: mainText,
        }}
      />
      <span
        className="inline tablet:hidden"
        // eslint-disable-next-line react/no-danger -- the rule was disabled before a comment became required
        dangerouslySetInnerHTML={{
          __html: text_mobile ?? mainText,
        }}
      />
    </>
  )

  if (link) {
    text = (
      <a className="after:absolute after:inset-0" href={link}>
        {text}
      </a>
    )
  }

  return (
    <div className="top-header-sticky-banner">
      <div className="container flex items-start">
        {Icon ? <Icon className="-my-0.5 mr-1 size-6 shrink-0" /> : null}
        {text}
      </div>
    </div>
  )
}
