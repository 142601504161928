import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { TENANT } from '../../tenant/tenantTypes'

import { CopyVoucherButton } from './CopyVoucherButton'

const VOUCHER_CODE_AT = 'REDCARE15AT'
const VOUCHER_CODE_DE = 'REDCARE15DE'
const FOOTNOTE_NUMBER_AT = 20
const FOOTNOTE_NUMBER_COM = 29

type DiscountBubbleProps = {
  tenant: TENANT
}

export const DiscountBubble: FC<DiscountBubbleProps> = ({ tenant }) => {
  const voucherCode = tenant === TENANT.COM ? VOUCHER_CODE_DE : VOUCHER_CODE_AT
  const footNoteNumber =
    tenant === TENANT.COM ? FOOTNOTE_NUMBER_COM : FOOTNOTE_NUMBER_AT

  return (
    <div className="absolute right-2 top-44 flex size-32 flex-col justify-center gap-1 rounded-full bg-light-tertiary">
      <span className="w-full text-center font-mono text-2xl">
        <FormattedMessage id="advertisingFlyoutBanner.promoCodePercent" />
      </span>
      <span className="mb-1 w-full text-center font-mono">
        <FormattedMessage id="advertisingFlyoutBanner.discount" />
        <sup>{footNoteNumber}</sup>
      </span>
      <span className="mx-auto rounded-md bg-light-secondary-high px-1 font-mono italic">
        {voucherCode}
      </span>
      <CopyVoucherButton textToCopy={voucherCode} />
    </div>
  )
}
